import React, { useEffect } from "react";
import "../css/page css/PaymentPage.css";

export default function SubscriptionPage() {
  const monthlySubscriptionPlans = [
    {
      name: "Silver",
      price: "19.00",
      duration: "1 Month",
      benefits: ["4 cluster shield ✔️", "API core protection ✔️", "Ddos Protection ❌", "SSL Protection ✔️"],
    },
    {
      name: "Gold",
      price: "29.00",
      duration: "1 Month",
      benefits: ["9 cluster shield ✔️", "Password Protection ✔️", "API core protection ✔️", "Ddos Protection ✔️", "SSL Protection ✔️", "Payment Protection ✔️"],
    },
    {
      name: "Diamond",
      price: "59.00",
      duration: "1 Month",
      benefits: ["16 cluster shield ✔️", "Password Protection ✔️", "Dedicated Server Cluster ✔️", "Files protection ✔️", "256bit Encryption ✔️", "API ultra protection ✔️", "Ddos Protection ✔️", "SSL Protection ✔️", "By-pass Protection ✔️", "Payment Protection ✔️"],
    },
  ];

  const yearlySubscriptionPlans = [
    {
      name: "Silver",
      oldPrice: "$228",
      price: "199.00",
      duration: "1 Year(Free Setup)",
      benefits: ["4 cluster shield ✔️", "API core protection ✔️", "Ddos Protection ❌", "SSL Protection ✔️"],
    },
    {
      name: "Gold",
      oldPrice: "$348",
      price: "299.00",
      duration: "1 Year(Free Setup)",
      benefits: ["9 cluster shield ✔️", "Password Protection ✔️", "API core protection ✔️", "Ddos Protection ✔️", "SSL Protection ✔️", "Payment Protection ✔️"],
    },
    {
      name: "Diamond",
      oldPrice: "$708",
      price: "649.00",
      duration: "1 Year(Free Setup)",
      benefits: ["16 cluster shield ✔️", "Password Protection ✔️", "Dedicated Server Cluster ✔️", "Files protection ✔️", "256bit Encryption ✔️", "API ultra protection ✔️", "Ddos Protection ✔️", "SSL Protection ✔️", "By-pass Protection ✔️", "Payment Protection ✔️"],
    },
  ];

  useEffect(() => {
    // Avoid re-initializing the PayPal buttons on every re-render
    if (window.paypal) {
      monthlySubscriptionPlans.forEach((plan, index) => {
        // Check if the button has already been rendered to prevent duplication
        if (!document.getElementById(`paypal-button-monthly-${index}`)?.hasChildNodes()) {
          window.paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: plan.price,
                      currency_code: "USD",
                    },
                  },
                ],
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then((details) => {
                alert(`Payment completed by ${details.payer.name.given_name}!`);
              });
            },
            onError: (err) => {
              console.error("PayPal Error:", err);
            },
          }).render(`#paypal-button-monthly-${index}`);
        }
      });

      yearlySubscriptionPlans.forEach((plan, index) => {
        // Check if the button has already been rendered to prevent duplication
        if (!document.getElementById(`paypal-button-yearly-${index}`)?.hasChildNodes()) {
          window.paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: plan.price,
                      currency_code: "USD",
                    },
                  },
                ],
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then((details) => {
                alert(`Payment completed by ${details.payer.name.given_name}!`);
              });
            },
            onError: (err) => {
              console.error("PayPal Error:", err);
            },
          }).render(`#paypal-button-yearly-${index}`);
        }
      });
    }
  }, []); // Empty dependency array ensures this effect runs only once on initial render

  return (
    <div className="paymentPage">
      {/* Monthly Plans */}
      <div className="subscription-page">
        <h1 className="title">Choose Monthly Plan</h1>
        <p className="subtitle">Select the subscription that suits you best!</p>
        <div className="subscription-cards">
          {monthlySubscriptionPlans.map((plan, index) => (
            <div key={index} className={`card ${plan.name.toLowerCase()}`}>
              <h2 className="card-title">{plan.name}</h2>
              <p className="price">{`$${plan.price}`}</p>
              <p className="duration">{plan.duration}</p>
              <ul className="benefits">
                {plan.benefits.map((benefit, i) => (
                  <li key={i}>{benefit}</li>
                ))}
              </ul>
              <div id={`paypal-button-monthly-${index}`} />
            </div>
          ))}
        </div>
      </div>

      {/* Yearly Plans */}
      <div className="subscription-page">
        <h1 className="title" style={{ color: "yellow" }}>Choose Yearly Plan</h1>
        <p className="subtitle">Select the subscription that suits you best!</p>
        <div className="subscription-cards">
          {yearlySubscriptionPlans.map((plan, index) => (
            <div key={index} className={`card ${plan.name.toLowerCase()}`}>
              <h2 className="card-title">{plan.name}</h2>
              <strike>
                <p className="oldPrice">{plan.oldPrice}</p>
              </strike>
              <p className="price">{`$${plan.price}`}</p>
              <p className="duration">{plan.duration}</p>
              <ul className="benefits">
                {plan.benefits.map((benefit, i) => (
                  <li key={i}>{benefit}</li>
                ))}
              </ul>
              <div id={`paypal-button-yearly-${index}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
